@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?huueoj');
  src:  url('fonts/icomoon.eot?huueoj#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?huueoj') format('truetype'),
    url('fonts/icomoon.woff?huueoj') format('woff'),
    url('fonts/icomoon.svg?huueoj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-save:before {
  content: "\e90c";
}
.icon-undo:before {
  content: "\e90d";
}
.icon-cancel:before {
  content: "\e905";
}
.icon-mail:before {
  content: "\e90e";
}
.icon-list:before {
  content: "\e904";
}
.icon-home:before {
  content: "\e90b";
}
.icon-copy:before {
  content: "\e90a";
}
.icon-pencil:before {
  content: "\e900";
}
.icon-snow:before {
  content: "\e917";
}
.icon-plus:before {
  content: "\f067";
}
.icon-check:before {
  content: "\f00c";
}
.icon-lock:before {
  content: "\f023";
}
.icon-check-circle:before {
  content: "\f058";
}
.icon-question:before {
  content: "\f059";
}
.icon-info:before {
  content: "\f05a";
}
.icon-exclamation:before {
  content: "\f06a";
}
.icon-warning:before {
  content: "\f071";
}
.icon-plane:before {
  content: "\f072";
}
.icon-stats:before {
  content: "\f080";
}
.icon-truck:before {
  content: "\f0d1";
}
.icon-clipboard:before {
  content: "\f0ea";
}
.icon-paste:before {
  content: "\f0ea";
}
.icon-document:before {
  content: "\f15c";
}
.icon-help:before {
  content: "\f1cd";
}
.icon-calculator:before {
  content: "\f1ec";
}
.icon-trash:before {
  content: "\f1f8";
}
.icon-ship:before {
  content: "\f21a";
}
.icon-examples:before {
  content: "\f277";
}
.icon-leaf:before {
  content: "\e901";
}
.icon-search:before {
  content: "\e902";
}
.icon-ham:before {
  content: "\e918";
}
.icon-external:before {
  content: "\e908";
}
.icon-atom:before {
  content: "\e903";
}
.icon-rail:before {
  content: "\e916";
}
